import { Api } from '@monorepo/shared/apiClient';
import {
  LogEntryCacheEvents,
  logEntryCacheRegister,
} from '@monorepo/shared/cacheRegisters/data2/logEntryCacheRegister';
import {
  LogFieldCacheEvents,
  logFieldCacheRegister,
} from '@monorepo/shared/cacheRegisters/data2/logFieldCacheRegister';
import {
  ResourceCacheEvents,
  resourceCacheRegister,
} from '@monorepo/shared/cacheRegisters/data2/resourceCacheRegister';
import {
  ResourcePropertyCacheEvents,
  resourcePropertyCacheRegister,
} from '@monorepo/shared/cacheRegisters/data2/resourcePropertyCacheRegister';
import {
  ResourceTypeCacheEvents,
  resourceTypeCacheRegister,
} from '@monorepo/shared/cacheRegisters/data2/resourceTypeCacheRegister';
import {
  getKeyParamsForInvalidation,
  getQueryConfig,
} from '@monorepo/shared/utils/queryUtils';
import { QueryRequest } from 'mapistry-shared';
import { queryCache, QueryConfig, usePaginatedQuery } from 'react-query';
import { OptionalKeys } from 'utility-types';

type KeyParams = Omit<
  Api.FetchLogQueryParams,
  OptionalKeys<Api.FetchLogQueryParams>
>;

const KEY_START = 'logQueries';

export const createQueryKey = ({
  logId,
  organizationId,
  projectId,
  query,
}: KeyParams) => [KEY_START, organizationId, logId, projectId, query] as const;

type Fetcher = Api.DataHookQueryFn<
  typeof createQueryKey,
  typeof Api.fetchLogQuery
>;

type UseLogQueryParams = Partial<Api.FetchLogQueryParams> & {
  config?: QueryConfig<Api.FetchLogQueryResponse, Api.ErrorResponse>;
};

const fetcher: Fetcher = (
  _: string,
  organizationId: string,
  logId: string,
  projectId: string,
  query: QueryRequest,
) =>
  Api.fetchLogQuery({
    logId,
    organizationId,
    projectId,
    query,
  });

export const useLogQuery = ({
  config: inputConfig,
  logId,
  organizationId,
  projectId,
  query,
}: UseLogQueryParams) => {
  const isEnabled = !!organizationId && !!logId && !!projectId && !!query;
  const config = getQueryConfig<Api.FetchLogQueryResponse>(
    inputConfig,
    isEnabled,
  );
  const key = isEnabled
    ? createQueryKey({
        logId,
        organizationId,
        projectId,
        query,
      })
    : undefined;
  const { resolvedData: queryResult, ...queryInfo } = usePaginatedQuery(
    key,
    fetcher,
    config,
  );
  return {
    queryResult,
    ...queryInfo,
  };
};

type QueryCache = ReturnType<typeof useLogQuery>['queryResult'] | undefined;
type NonEmptyQueryCache = Exclude<QueryCache, undefined>;

export function getCache(keyParams: KeyParams): QueryCache {
  return queryCache.getQueryData<QueryCache>(createQueryKey(keyParams));
}

export function setCache(
  keyParams: KeyParams,
  newItems: NonEmptyQueryCache,
): void {
  queryCache.setQueryData(createQueryKey(keyParams), newItems);
}

type InvalidateCacheParams = Omit<
  KeyParams,
  'logId' | 'projectId' | 'query'
> & {
  logId?: string;
  projectId?: string;
};

// allow invalidating all entries lists (across projects) for a log
async function invalidateCache(
  keyParams: InvalidateCacheParams,
): Promise<void> {
  await queryCache.invalidateQueries(
    getKeyParamsForInvalidation([
      KEY_START,
      keyParams.organizationId,
      keyParams.logId,
      keyParams.projectId,
    ]),
  );
}

const cacheRegisterInvalidator = {
  hookName: 'useLogQuery',
  callback: (keyParams: InvalidateCacheParams) => invalidateCache(keyParams),
};

logEntryCacheRegister(
  [
    LogEntryCacheEvents.CREATE,
    LogEntryCacheEvents.UPDATE,
    LogEntryCacheEvents.DELETE,
  ],
  cacheRegisterInvalidator,
);

logFieldCacheRegister(
  [
    LogFieldCacheEvents.CREATE,
    LogFieldCacheEvents.DELETE,
    LogFieldCacheEvents.UPDATE,
  ],
  cacheRegisterInvalidator,
);

resourceCacheRegister(
  [
    ResourceCacheEvents.CREATE,
    ResourceCacheEvents.DELETE,
    ResourceCacheEvents.UPDATE,
  ],
  cacheRegisterInvalidator,
);

resourcePropertyCacheRegister(
  [
    ResourcePropertyCacheEvents.CREATE,
    ResourcePropertyCacheEvents.DELETE,
    ResourcePropertyCacheEvents.UPDATE,
  ],
  cacheRegisterInvalidator,
);

resourceTypeCacheRegister(
  [
    ResourceTypeCacheEvents.CREATE,
    ResourceTypeCacheEvents.DELETE,
    ResourceTypeCacheEvents.UPDATE,
  ],
  cacheRegisterInvalidator,
);
