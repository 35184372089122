import { FullScreenLoadingPage } from '@monorepo/shared/componentsV2/Loading';
import { useDisableSubnav } from '@monorepo/shared/hooks/subnav/useDisableSubnav';
import { getPathToInspections } from '@monorepo/shared/routing';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import { useFormTemplate } from '../../hooks/useFormTemplate';
import { EditFormTemplateHeader } from './EditFormTemplateHeader';
import { EditFormTemplateSectionFields } from './EditFormTemplateSectionFields';
import { EditFormTemplateSections } from './EditFormTemplateSections';
import { EditFormTemplateSitesUsingFormTemplate } from './EditFormTemplateSitesUsingFormTemplate';
import { ColumnWrapper, MainContent, Sidebar, StyledMain } from './styled';
import type { FormTemplateSectionType } from './types';

export function EditFormTemplatePage() {
  const { organizationId, formTemplateSlug, projectId } = useParams();
  const { formTemplate, isLoading } = useFormTemplate({
    slug: formTemplateSlug,
    organizationId,
  });

  const [activeSection, setActiveSection] = useState<
    FormTemplateSectionType | undefined
  >(formTemplate?.groups[0]);

  useDisableSubnav();

  const closeUrl = getPathToInspections(organizationId, projectId);

  return isLoading ? (
    <FullScreenLoadingPage />
  ) : (
    <StyledMain>
      <EditFormTemplateHeader name={formTemplate?.name} closeUrl={closeUrl} />
      <ColumnWrapper>
        <Sidebar>
          <EditFormTemplateSitesUsingFormTemplate sites={[]} />
          <EditFormTemplateSections
            sections={formTemplate?.groups}
            setActiveSection={setActiveSection}
          />
        </Sidebar>
        <MainContent>
          <EditFormTemplateSectionFields
            sectionName={activeSection?.name}
            fields={activeSection?.fields}
          />
        </MainContent>
      </ColumnWrapper>
    </StyledMain>
  );
}
