import { Main } from '@monorepo/shared/componentsV2/Main';
import {
  contentPadding,
  fillHeightAndScrollable,
  page,
  verticalLayout,
} from '@monorepo/shared/styles/layout';
import styled from 'styled-components';

export const StyledMain = styled(Main)`
  ${verticalLayout}
  ${page}
`;

export const ColumnWrapper = styled.div`
  display: flex;
`;

export const MainContent = styled.div`
  ${contentPadding}
  ${fillHeightAndScrollable}
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 70%;
`;

export const Sidebar = styled.div`
  ${contentPadding}
  ${fillHeightAndScrollable}
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  width: 30%;
  min-width: 300px;
  color: ${({ theme }) => theme.colors.gray333};
`;
