import { Api } from '@monorepo/shared/apiClient';
import { getQueryConfig } from '@monorepo/shared/utils/queryUtils';
import { FormTemplateFieldGroup } from 'mapistry-shared';
import { QueryConfig, useQuery } from 'react-query';

interface FormTemplateResponse {
  readonly id: string;
  name: string;
  slug: string;
  groups: FormTemplateFieldGroup[];
}

type UseFormTemplateParams = Partial<Api.FetchFormTemplateParams> & {
  config?: QueryConfig<FormTemplateResponse, Api.ErrorResponse>;
};

type KeyParams = Api.FetchFormTemplateParams;

export const createKey = ({ slug, organizationId }: KeyParams) =>
  ['form-template', slug, organizationId] as const;

type Fetcher = Api.DataHookQueryFn<
  typeof createKey,
  typeof Api.fetchFormTemplate
>;

const fetcher: Fetcher = (_, slug, organizationId) =>
  Api.fetchFormTemplate({ slug, organizationId });

export const useFormTemplate = ({
  config: inputConfig,
  slug,
  organizationId,
}: UseFormTemplateParams) => {
  const isEnabled = !!organizationId && !!slug;
  const config = getQueryConfig(inputConfig, isEnabled);
  const key = isEnabled ? createKey({ slug, organizationId }) : undefined;
  const { data, ...queryInfo } = useQuery(key, fetcher, config);

  return {
    ...queryInfo,
    formTemplate: data,
  };
};
