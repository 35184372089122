import APP from '@monorepo/old-web/js/config';
import { ErrorBoundary } from '@monorepo/shared/componentsV2/ErrorBoundary';
import { Loading } from '@monorepo/shared/componentsV2/Loading';
import { Main } from '@monorepo/shared/componentsV2/Main';
import { useLogsWithSiteConfig } from '@monorepo/shared/hooks/logs/useLogsWithSiteConfig';
import { useCurrentProject } from '@monorepo/shared/hooks/useCurrentProject';
import {
  fillHeightAndScrollable,
  page,
  verticalLayout,
} from '@monorepo/shared/styles/layout';
import { useFeatureFlags } from 'mapistry-shared/api';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { LogTable } from '../LogTable';
import { EmptyLogsSalesPage } from './EmptyLogsSalesPage';
import {
  LogsWidgets,
  useCreateWidgetRenderMap,
} from './GenericLogs/LogsWidgets';
import { LogsPageHeader } from './LogsPageHeader';

const Page = styled.div`
  ${verticalLayout}
  ${page}
`;

const StyledMain = styled(Main)`
  ${fillHeightAndScrollable}
`;

function LogsPageContent({
  filter,
  setHasLogsOnPage,
}: {
  filter: string;
  setHasLogsOnPage: (arg0: boolean) => void;
}) {
  const { project } = useCurrentProject();
  const { isData2Enabled } = useFeatureFlags();

  APP.projectId = project?.id; // Legacy code grabs projectId from the APP object

  const { logsWithSiteConfig = [], isLoading: isLoadingData2 } =
    useLogsWithSiteConfig({
      organizationId: project?.organization_id,
      projectId: project?.id,
      config: { enabled: !!isData2Enabled }, // boolean-ize this so we don't start loading logs if the feature flag hasn't loaded yet
    });

  const widgets = useCreateWidgetRenderMap(project?.id, project?.name);

  if (isLoadingData2 || !widgets) {
    return <Loading />;
  }

  const hasLogsOnPage =
    (isData2Enabled && logsWithSiteConfig.length > 0) || widgets.length > 0;
  setHasLogsOnPage(hasLogsOnPage);

  return (
    <>
      {isData2Enabled && (
        <LogTable filter={filter} logsWithSiteConfig={logsWithSiteConfig} />
      )}
      <LogsWidgets filter={filter} widgets={widgets} />
      {!hasLogsOnPage && <EmptyLogsSalesPage />}
    </>
  );
}

export function LogsPage() {
  const { project } = useCurrentProject();

  const [filter, setFilter] = useState('');
  const [hasLogsOnPage, setHasLogsOnPage] = useState(false);

  // clear filter when switching projects
  useEffect(() => {
    setFilter('');
  }, [project?.id]);

  return (
    <Page>
      <LogsPageHeader
        onSearch={hasLogsOnPage ? setFilter : undefined}
        filter={filter}
      />
      <StyledMain>
        <ErrorBoundary>
          <LogsPageContent
            filter={filter}
            setHasLogsOnPage={setHasLogsOnPage}
          />
        </ErrorBoundary>
      </StyledMain>
    </Page>
  );
}
