import { ErrorBoundary } from '@monorepo/shared/componentsV2/ErrorBoundary';
import { Main } from '@monorepo/shared/componentsV2/Main';
import {
  fillHeightAndScrollable,
  verticalLayout,
} from '@monorepo/shared/styles/layout';
import React from 'react';
import styled from 'styled-components';
import { EditViewInfoModalProvider } from '../../contexts/EditViewInfoModalContext';
import { ViewsTable } from './ViewsTable';
import { ViewsToolbar } from './ViewsToolbar';

const Container = styled(Main)`
  ${verticalLayout}
`;

const StyledViewsTable = styled(ViewsTable)`
  ${fillHeightAndScrollable}
`;

export function ViewsTab() {
  return (
    <ErrorBoundary>
      <EditViewInfoModalProvider>
        <Container>
          <ViewsToolbar />
          <StyledViewsTable />
        </Container>
      </EditViewInfoModalProvider>
    </ErrorBoundary>
  );
}
