import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import { Loading } from '@monorepo/shared/componentsV2/Loading';
import { useNavigateToSamePageForDifferentProject } from '@monorepo/shared/hooks/navigation/useNavigateToSamePageForDifferentProject';
import { useCurrentProject } from '@monorepo/shared/hooks/useCurrentProject';
import { useOpenClose } from '@monorepo/shared/hooks/useOpenClose';
import { usePrevious } from '@monorepo/shared/hooks/usePrevious';
import React, { useEffect, useRef } from 'react';
import { SitePickerForUser } from '../SitePickerForUser';
import { VisuallyHidden } from '../VisuallyHidden';
import { ClickableHeader } from './ClickableHeader';

export function SubnavHeaderWithProjectSelect() {
  const { project } = useCurrentProject();

  const navigateToSamePageForDifferentProject =
    useNavigateToSamePageForDifferentProject();

  const titleRef = useRef<HTMLDivElement>(null);
  const [pickerIsOpen, openPicker, closePicker] = useOpenClose();
  const prevPickerIsOpen = usePrevious(pickerIsOpen);

  useEffect(() => {
    if (!pickerIsOpen && prevPickerIsOpen) {
      titleRef.current?.focus();
    }
  }, [prevPickerIsOpen, pickerIsOpen]);

  if (!project) return <Loading size="small" />;

  return (
    <nav aria-label="Site select">
      {pickerIsOpen ? (
        <SitePickerForUser
          blurOnSelect
          disableClearable
          id="overallSitePicker"
          textFieldProps={{ autoFocus: true }}
          onBlur={closePicker}
          onChange={({ target: { value } }) => {
            // value should never be null since we're passing 'disableClearable', but MUI Autocomplete
            //  types aren't quite there yet, and this is a good double check
            if (!value) return;
            navigateToSamePageForDifferentProject(value);
          }}
          selectedProjectId={project?.id}
          variant="underline"
        />
      ) : (
        <ClickableHeader onClick={openPicker} titleRef={titleRef}>
          {project?.name}
          <VisuallyHidden>- click to switch sites</VisuallyHidden>
          <ArrowDropDown aria-hidden />
        </ClickableHeader>
      )}
    </nav>
  );
}
