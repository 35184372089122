import { ErrorBoundary } from '@monorepo/shared/componentsV2/ErrorBoundary';
import { RoutesWith404 } from '@monorepo/shared/componentsV2/RoutesWith404';

import { useFeatureFlags } from 'mapistry-shared/api';
import React from 'react';
import { Navigate, Route } from 'react-router-dom-v5-compat';
import { EditViewPage } from './EditViewPage';
import { LogEntriesTab } from './LogEntriesTab';
import { LogFrequencyRequirementsTab } from './LogFrequencyRequirements';
import { LogsPage } from './LogsPage';
import { SingleLogPage } from './SingleLogPage';
import { ViewDetailTab } from './ViewDetailTab';
import { ViewsTab } from './ViewsTab';

export function Logs() {
  const { isData2Enabled } = useFeatureFlags();

  return (
    <RoutesWith404>
      <Route index element={<LogsPage />} />
      {isData2Enabled && (
        <Route
          path=":logId"
          element={
            <ErrorBoundary>
              <SingleLogPage />
            </ErrorBoundary>
          }
        >
          <Route index element={<Navigate replace to="records" />} />
          <Route path="records">
            <Route index element={<Navigate replace to="pages" />} />
            <Route path="pages">
              <Route index element={<Navigate replace to="0" />} />
              <Route path=":pageNumber">
                <Route index element={<LogEntriesTab />} />
              </Route>
            </Route>
          </Route>
          <Route path="views">
            <Route index element={<ViewsTab />} />
            <Route path="new" element={<EditViewPage />} />
            <Route path=":viewId">
              <Route index element={<Navigate replace to="pages" />} />
              <Route path="pages">
                <Route index element={<Navigate replace to="0" />} />
                <Route path=":pageNumber">
                  <Route index element={<ViewDetailTab />} />
                </Route>
              </Route>
              <Route path="edit" element={<EditViewPage />} />
            </Route>
          </Route>
          <Route
            path="frequencySettings"
            element={<LogFrequencyRequirementsTab />}
          />
        </Route>
      )}
    </RoutesWith404>
  );
}
