import { Button } from '@monorepo/shared/componentsV2/Button';
import { ButtonsGroup } from '@monorepo/shared/componentsV2/ButtonsGroup';
import { A } from '@monorepo/shared/componentsV2/Link';
import { Toolbar } from '@monorepo/shared/componentsV2/Toolbar';
import { useHasProjectSettingsPermissions } from '@monorepo/shared/hooks/permissions/useHasPermissions';
import { header4 } from '@monorepo/shared/styles/text';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import styled from 'styled-components';
import { viewsProductGuideUrl } from '../../constants';

const Title = styled.h2`
  ${header4}
`;

export function ViewsToolbar() {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const canEditViews = useHasProjectSettingsPermissions(projectId);

  return (
    <Toolbar
      leftActions={<Title>All Views</Title>}
      rightActions={
        <ButtonsGroup>
          <A color="grey" href={viewsProductGuideUrl} target="_blank">
            Learn about Views
          </A>
          {canEditViews && (
            <Button
              color="primary"
              onClick={() => navigate('new')}
              variant="contained"
            >
              Create new View
            </Button>
          )}
        </ButtonsGroup>
      }
      size="small"
      variant="noDropShadow"
    />
  );
}
