import CachedIcon from '@material-ui/icons/Cached';
import { Button } from '@monorepo/shared/componentsV2/Button';
import { ButtonsGroup } from '@monorepo/shared/componentsV2/ButtonsGroup';
import { QueryOperationType } from 'mapistry-shared';
import React from 'react';
import styled from 'styled-components';
import {
  useLastQueryStepIsInvalid,
  useQuerySteps,
  useQueryStepsClickHandler,
} from '../../contexts/QueryStepsContext';
import {
  getDisplayNameForOperationType,
  getIconForOperationType,
} from '../QuerySteps/QueryStepFactory';
import { QueryStepInvalidToolbar } from './QueryStepInvalidToolbar';
import { LeftSide, StyledToolbar, Title } from './styled';

const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.white};
`;

export function QueryStepToolbar() {
  const { clickHandler } = useQueryStepsClickHandler();
  const { lastQueryStepIsInvalid } = useLastQueryStepIsInvalid();
  const { handleAddQueryStep, queryStepsArePristine } = useQuerySteps();

  if (lastQueryStepIsInvalid) {
    return <QueryStepInvalidToolbar />;
  }

  return (
    <StyledToolbar
      leftActions={
        <LeftSide>
          <Title>Add Query Step:</Title>
          <ButtonsGroup>
            {[
              QueryOperationType.AGGREGATION,
              QueryOperationType.ROLLING_CALCULATION,
              QueryOperationType.FILTER,
              QueryOperationType.LIMIT,
            ].map((queryOperationType) => (
              <StyledButton
                key={queryOperationType}
                color="primary"
                variant="outlined"
                onClick={clickHandler(() =>
                  handleAddQueryStep(queryOperationType),
                )}
              >
                {getIconForOperationType(queryOperationType)}
                {getDisplayNameForOperationType(queryOperationType)}
              </StyledButton>
            ))}
          </ButtonsGroup>
        </LeftSide>
      }
      position="relative"
      rightActions={
        <StyledButton
          color="primary"
          disabled={queryStepsArePristine}
          onClick={clickHandler()}
          variant="outlined"
        >
          <CachedIcon /> Run
        </StyledButton>
      }
      variant="noDropShadow"
    />
  );
}
