import React from 'react';
import type { FormTemplateSectionFieldType } from './types';

type EditFormTemplateSectionFieldsProps = {
  sectionName?: string;
  fields?: FormTemplateSectionFieldType[];
};

const FormTemplateSectionField = ({ name }: FormTemplateSectionFieldType) => (
  <div>Field {name}</div>
);

export function EditFormTemplateSectionFields({
  sectionName,
  fields,
}: EditFormTemplateSectionFieldsProps) {
  const placeholder = fields?.map((field) => (
    <FormTemplateSectionField name={field.name} key={field.name} />
  ));

  return (
    <div>
      <h2>{sectionName}</h2>
      {placeholder}
    </div>
  );
}
