import { FieldTypePreviewFactory } from '@monorepo/shared/componentsV2/fieldDataType/FieldTypePreview/FieldTypePreviewFactory';
import { FieldResponse } from 'mapistry-shared';
import React, { useMemo } from 'react';

type ResourcePropertyPreviewProps = {
  className?: string;
  onDelete: (p: FieldResponse) => void;
  onEdit: (p: FieldResponse) => void;
  property: FieldResponse;
};

export function ResourcePropertyPreview({
  className,
  onDelete,
  onEdit,
  property,
}: ResourcePropertyPreviewProps) {
  const menuOptions = useMemo(
    () => [
      {
        displayName: 'Edit',
        onClick: () => onEdit(property),
      },
      {
        displayName: 'Delete',
        onClick: () => onDelete(property),
      },
    ],
    [onDelete, onEdit, property],
  );

  return (
    <FieldTypePreviewFactory
      actionMenuOptions={menuOptions}
      className={className}
      fieldTypeName="property"
      field={property}
    />
  );
}
