import React from 'react';
import { InfoContainer } from './styled';

export function EmptyDatasetList() {
  return (
    <InfoContainer>
      There are no data sources available for this organization. They will
      become available once there are Logs or Resource types configured.
    </InfoContainer>
  );
}
