import ErrorIcon from '@material-ui/icons/Error';
import { LimitColumnValue } from '@monorepo/logs/src/components/ViewResultsTable/resultsMapper';
import { VisuallyHidden } from '@monorepo/shared/componentsV2/VisuallyHidden';
import { bodyMedium } from '@monorepo/shared/styles/text';
import React from 'react';
import styled from 'styled-components';

const NO_LIMIT_MESSAGE = 'No limit';

function getLabel(props: { minThreshold?: string; maxThreshold?: string }) {
  const { minThreshold, maxThreshold } = props;
  if (minThreshold == null && maxThreshold == null) return NO_LIMIT_MESSAGE;
  const isRange = minThreshold !== undefined && maxThreshold !== undefined;
  const isMax = minThreshold === undefined && maxThreshold !== undefined;
  if (isRange) return `${minThreshold} - ${maxThreshold}`;
  if (isMax) return `≤ ${maxThreshold}`;
  return `≥ ${minThreshold}`;
}

const Container = styled.div<{ $isExceedance: boolean }>`
  display: flex;
  align-items: center;
  float: right;
  white-space: nowrap;
  ${({ $isExceedance, theme }) =>
    $isExceedance ? `${bodyMedium}; color: ${theme.colors.darkRed};` : ''}
  & svg {
    margin-right: 0.25rem;
    fill: ${({ theme }) => theme.colors.darkRed};
  }
`;

export const LimitTableCell = ({
  value: { minThreshold, maxThreshold, isExceedance },
}: {
  value: LimitColumnValue;
}) => (
  <Container $isExceedance={isExceedance}>
    {isExceedance && (
      <>
        <ErrorIcon />
        <VisuallyHidden>Is Exceedance</VisuallyHidden>
      </>
    )}
    {getLabel({ minThreshold, maxThreshold })}
  </Container>
);
