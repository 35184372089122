import { RowStatus } from '@monorepo/shared/componentsV2/Table';
import {
  BuiltInLogEntryFields,
  ColumnSet,
  ColumnType,
  LimitColumnSet,
  QueryColumn,
  QueryResponse,
  ResultRow,
} from 'mapistry-shared';
import {
  ColumnName,
  ColumnValue,
} from 'mapistry-shared/dist/consts/ColumnValue';

export enum SpecialColumnType {
  LIMIT_COLUMN = 'limitColumn',
}

export interface LimitColumnValue {
  maxThreshold?: string;
  minThreshold?: string;
  isExceedance: boolean;
}
export type DisplayColumnValue = ColumnValue | LimitColumnValue;
export type DisplayColumnType = ColumnType | SpecialColumnType;
export type DisplayQueryColumn = Omit<QueryColumn, 'columnType'> & {
  columnType: ColumnType | SpecialColumnType;
};

export type DisplayResultRow = Record<ColumnName, DisplayColumnValue> & {
  id: string;
  rowStatus?: RowStatus;
};
function isString(x: unknown): x is string {
  return typeof x === 'string';
}

function isLimitThresholdColumn(
  columnName: ColumnName,
  limitColumnSets: LimitColumnSet[],
) {
  return limitColumnSets
    .flatMap((lcs) => [lcs.minThresholdColumnName, lcs.maxThresholdColumnName])
    .filter(isString)
    .includes(columnName);
}

function isADisplayColumnInAColumnSet(
  columnName: ColumnName,
  columnSets: ColumnSet[],
): boolean {
  return columnSets.some((cs) => cs.displayColumn === columnName);
}

export function getDisplayColumnType(
  columnName: ColumnName,
  columnType: ColumnType,
  limitColumnSets: LimitColumnSet[],
): DisplayColumnType {
  if (limitColumnSets.some((lcs) => lcs.exceedanceColumnName === columnName)) {
    return SpecialColumnType.LIMIT_COLUMN;
  }
  return columnType;
}

export function getDisplayQueryColumns(
  queryColumns: QueryColumn[],
  limitColumnSets: LimitColumnSet[],
  columnSets: ColumnSet[],
): DisplayQueryColumn[] {
  return queryColumns
    .filter(
      ({ columnName }) =>
        !(
          [
            BuiltInLogEntryFields.ID,
            BuiltInLogEntryFields.SITE_ID,
            BuiltInLogEntryFields.SITE_NAME,
          ] as string[]
        ).includes(columnName) &&
        !isLimitThresholdColumn(columnName, limitColumnSets) &&
        !isADisplayColumnInAColumnSet(columnName, columnSets),
    )
    .map((queryColumn) => ({
      ...queryColumn,
      columnType: getDisplayColumnType(
        queryColumn.columnName,
        queryColumn.columnType,
        limitColumnSets,
      ),
    }));
}

function getLimitColumnValues(
  limitColumnSets: LimitColumnSet[],
  resultRow: ResultRow,
) {
  return limitColumnSets.reduce((it, lcs) => {
    const isExceedance = resultRow[lcs.exceedanceColumnName] as boolean;
    const maxThreshold = lcs.maxThresholdColumnName
      ? (resultRow[lcs.maxThresholdColumnName] as string)
      : undefined;
    const minThreshold = lcs.minThresholdColumnName
      ? (resultRow[lcs.minThresholdColumnName] as string)
      : undefined;
    const limitColumnValue: LimitColumnValue = {
      isExceedance,
      maxThreshold,
      minThreshold,
    };
    return {
      ...it,
      [lcs.exceedanceColumnName]: limitColumnValue,
    };
  }, {} as Record<string, LimitColumnValue>);
}

export function mapResultRows(
  queryResponse: QueryResponse,
): DisplayResultRow[] {
  const {
    limitColumnSets,
    results: { data: resultRows },
  } = queryResponse;
  return resultRows.map((resultRow, idx): DisplayResultRow => {
    const limitColumnValues = getLimitColumnValues(limitColumnSets, resultRow);

    const isRowExceedance = Object.values(limitColumnValues).some(
      (lcv) => lcv.isExceedance,
    );
    const displayResultRow: DisplayResultRow = {
      id: `${idx}`,
      // We don't need to worry about collisions here bc all column values are upper snake cased
      ...resultRow,
      ...limitColumnValues,
    };
    if (isRowExceedance) {
      displayResultRow.rowStatus = RowStatus.ERROR;
    }
    return displayResultRow;
  });
}
