import {
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom-v5-compat';
import { useProjectsForUser } from '../useProjectsForUser';

type NavigateToSamePageForDifferentProject = (
  projectIdToSwitchTo: string,
) => void;

/**
 * For use in the vortex only (uses react-router-dom-v5-compat)
 */
export const useNavigateToSamePageForDifferentProject: () => NavigateToSamePageForDifferentProject =
  () => {
    const { pathname } = useLocation();
    const { projectId, organizationId } = useParams();
    const { projects } = useProjectsForUser();
    const navigate = useNavigate();

    return (projectIdToSwitchTo: string) => {
      const { id: newProjectId, organization_id: newOrgId } =
        projects?.find((p) => p.id === projectIdToSwitchTo) || {};

      if (!newProjectId || !newOrgId) {
        throw new Error(
          "You are attempting to switch to a project the user doesn't have access to",
        );
      }

      const currentUrl = pathname;
      const newUrl = currentUrl
        .replace(`${organizationId}`, newOrgId)
        .replace(`${projectId}`, newProjectId);

      navigate(newUrl);
    };
  };
