import { ActionMenu } from '@monorepo/shared/componentsV2/ActionMenu';
import { SiteLogViewResponse } from 'mapistry-shared';
import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import { useEditViewInfoModal } from '../../hooks/useEditViewInfoModal';
import { useViewDeleteWithConfirm } from '../../hooks/useViewDelete';

interface ViewsTableActionsProps {
  view: SiteLogViewResponse;
}

export function ViewsTableActions({ view }: ViewsTableActionsProps) {
  const { logId, organizationId, projectId } = useParams();
  const { openModal } = useEditViewInfoModal();
  const handleDelete = useViewDeleteWithConfirm({
    logId,
    organizationId,
    projectId,
    view,
  });

  const handleEditDetails = useCallback(
    () => openModal(view),
    [openModal, view],
  );

  return (
    <ActionMenu
      altText={`Action menu for ${view.name} View`}
      options={[
        {
          displayName: 'Edit details',
          onClick: handleEditDetails,
        },
        {
          displayName: 'Delete',
          onClick: handleDelete,
        },
      ]}
    />
  );
}
