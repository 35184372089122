import MoreVert from '@material-ui/icons/MoreVert';
import { IconButton } from '@monorepo/shared/componentsV2/Button/IconButton';
import { VisuallyHidden } from '@monorepo/shared/componentsV2/VisuallyHidden';
import React from 'react';
import { Menu } from './Menu';

type ActionMenuProps = {
  options: React.ComponentProps<typeof Menu>['options'];
  altText?: string;
};

export function ActionMenu({
  options,
  altText = 'Action menu',
}: ActionMenuProps) {
  return (
    <Menu
      options={options}
      buttonElement={
        <IconButton color="primary">
          <MoreVert />
          <VisuallyHidden>{altText}</VisuallyHidden>
        </IconButton>
      }
    />
  );
}
