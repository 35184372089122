import { FieldValidator } from 'final-form';
import { Formula, FormulaFieldResponse, FormulaTerms } from 'mapistry-shared';
import React, { useMemo } from 'react';
import { useField } from 'react-final-form';
import styled from 'styled-components';
import { compose, isRequired } from '../../utils/validators';
import { TextField } from '../fields/TextField';
import { Input } from '../Input';
import { ModalFormInstructions } from '../modals/contents/ModalFormInstructions';
import { Tooltip } from '../Tooltip';

const FieldLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  max-width: 55rem;
  margin: 0 0 0.5rem;
`;

const FieldLabelInput = styled(TextField)`
  flex-direction: column;
  width: 35rem;
  margin-bottom: 0;
` as typeof TextField;

const Units = styled(Input)`
  margin-left: 1rem;

  & .MuiInputBase-root {
    background-color: ${({ theme }) => theme.colors.grayeee};
  }
`;

const isValidFormula = (terms: FormulaTerms) => (value?: string) => {
  const validation = Formula.isValid(value, terms);
  if (validation.ok) {
    return undefined;
  }
  return validation.message;
};

export interface FormulaFormValues {
  description: string;
  expression: string;
  name: string;
}

interface FormulaFormProps {
  sampleFormulaTerms?: FormulaTerms;
  tokenList: React.ReactElement;
}

export function FormulaForm({
  sampleFormulaTerms,
  tokenList,
}: FormulaFormProps) {
  const { input, meta } =
    useField<FormulaFieldResponse['expression']>('expression');

  const units = useMemo(
    () =>
      sampleFormulaTerms && input.value && meta.valid
        ? Formula.determineOutput(input.value, sampleFormulaTerms)?.units || ''
        : '',
    [input.value, meta.valid, sampleFormulaTerms],
  );

  const isValid = useMemo<FieldValidator<string>>(
    () =>
      sampleFormulaTerms
        ? compose(isRequired, isValidFormula(sampleFormulaTerms))
        : isRequired,
    [sampleFormulaTerms],
  );

  return (
    <>
      <ModalFormInstructions>
        Enter a formula using the italicized available names below with common
        mathematical symbols (such as +, -, *, /, ^). Formulas are unit-aware,
        so conversions happen automatically. For a complete list of formula
        functions, operators, and units, along with example formulas, see the
        product guide.
      </ModalFormInstructions>
      <FieldLabel>
        <FieldLabelInput<FormulaFormValues['name']>
          label="Formula Name"
          name="name"
          validate={isRequired}
          required
        />
      </FieldLabel>
      <FieldLabel>
        <FieldLabelInput<FormulaFormValues['description']>
          label="Description"
          multiline
          name="description"
        />
      </FieldLabel>
      <FieldLabel>
        <FieldLabelInput<FormulaFormValues['expression']>
          label="Type a formula here"
          name="expression"
          validate={isValid}
          required
        />
        <Units label="Output" name="Output" readOnly value={units} />
        <Tooltip title="Output units are determined by your formula. To change units, either type the conversion calculation with units as part of your formula, or select an already configured formula and type “to feet” to convert the output to units of feet (for example)." />
      </FieldLabel>
      <FieldLabel>{tokenList}</FieldLabel>
    </>
  );
}
