import CloseIcon from '@material-ui/icons/Close';
import { Button } from '@monorepo/shared/componentsV2/Button';
import { IconButton } from '@monorepo/shared/componentsV2/Button/IconButton';
import { Header } from '@monorepo/shared/componentsV2/Header';
import { VisuallyHidden } from '@monorepo/shared/componentsV2/VisuallyHidden';
import { useModal } from '@monorepo/shared/hooks/useModalV2';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';

type EditFormTemplateHeaderProps = {
  name?: string;
  closeUrl: string;
};
const IS_SUBMITTING_PLACEHOLDER = false;
const ONCLICK_PLACEHOLDER = () => null;
const CAN_SUBMIT_PLACEHOLDER = true;
const NEEDS_CONFIRM_PLACEHOLDER = false;

export function EditFormTemplateHeader({
  name,
  closeUrl,
}: EditFormTemplateHeaderProps) {
  const { confirm } = useModal();
  const navigate = useNavigate();

  const formIsDirty = NEEDS_CONFIRM_PLACEHOLDER;

  const handleClose = useCallback(async () => {
    const isConfirmed =
      !formIsDirty ||
      (await confirm({
        title: 'Close Editor',
        description: (
          <>
            Are you sure you want to close without saving? You may have unsaved
            changes.
          </>
        ),
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        danger: true,
      }));
    if (isConfirmed) {
      navigate(closeUrl);
    }
  }, [closeUrl, confirm, formIsDirty, navigate]);

  const title = `Editing ${name}`;
  const isSubmitting = IS_SUBMITTING_PLACEHOLDER;
  const canSubmit = CAN_SUBMIT_PLACEHOLDER;
  const handleSubmit = ONCLICK_PLACEHOLDER;

  return (
    <Header
      title={title}
      leftActions={
        <IconButton disabled={isSubmitting} onClick={handleClose}>
          <VisuallyHidden>Close</VisuallyHidden>
          <CloseIcon />
        </IconButton>
      }
      rightActions={
        <Button
          type="button"
          color="primary"
          disabled={isSubmitting || !canSubmit}
          onClick={handleSubmit}
          variant="contained"
        >
          Save Changes
        </Button>
      }
    />
  );
}
