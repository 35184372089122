import React from 'react';

type EditFormTemplateSitesUsingFormTemplateProps = {
  sites: Record<string, unknown>[];
};

export function EditFormTemplateSitesUsingFormTemplate({
  sites,
}: EditFormTemplateSitesUsingFormTemplateProps) {
  const placeholder = <div>Sites Using this Form Template {sites.length}</div>;

  return placeholder;
}
