import MuiChip from '@material-ui/core/Chip';
import React from 'react';
import styled from 'styled-components';

const Chip = styled(MuiChip)`
  height: 1.375rem;
  font-size: 0.875rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.gray666};
  background-color: ${({ theme }) => theme.colors.grayeee};
  border-radius: 3px;

  & span {
    padding: 0 0.5rem;
  }
`;

const PrimaryChip = styled(Chip)`
  color: ${({ theme }) => theme.colors.green};
  background-color: ${({ theme }) => theme.colors.green24};
`;

const SecondaryChip = styled(Chip)`
  color: ${({ theme }) => theme.colors.darkRed};
  background-color: ${({ theme }) => theme.colors.red32};
`;

type BadgeProps = React.ComponentProps<typeof Chip>;

export function Badge({ color, ...props }: BadgeProps) {
  switch (color) {
    case 'primary':
      return <PrimaryChip color={color} {...props} />;
    case 'secondary':
      return <SecondaryChip color={color} {...props} />;
    default:
      return <Chip {...props} />;
  }
}
