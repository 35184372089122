import React from 'react';
import type { FormTemplateSectionType } from './types';

type FormTemplateSectionsProps = {
  sections?: FormTemplateSectionType[];
  setActiveSection: React.Dispatch<
    React.SetStateAction<FormTemplateSectionType | undefined>
  >;
};

const FormTemplateSection = ({
  name,
  clickHandler,
}: FormTemplateSectionType) => (
  <div>
    <button type="button" onClick={clickHandler}>
      Section {name}
    </button>
  </div>
);

export function EditFormTemplateSections({
  sections,
  setActiveSection,
}: FormTemplateSectionsProps) {
  const placeholder = sections?.map((section) => (
    <FormTemplateSection
      name={section.name}
      key={section.name}
      clickHandler={() => {
        setActiveSection(section);
      }}
    />
  ));

  /* SPAN because react fragment throws a lint error with a single element, 
  placeholder (array of elements) throws a lint error without a wrapper */
  return <span>{placeholder}</span>;
}
