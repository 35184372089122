import { Api } from '@monorepo/shared/apiClient';
import {
  invalidateViewCache,
  ViewCacheEvents,
} from '@monorepo/shared/cacheRegisters/data2/viewCacheRegister';
import { SiteLogViewResponse } from 'mapistry-shared';
import { MutationConfig, useMutation } from 'react-query';
import { setCache as setViewCache } from './useView';

type useViewCreateParams = {
  config?: MutationConfig<SiteLogViewResponse, Api.ErrorResponse>;
};

export const useViewCreate = (params?: useViewCreateParams) =>
  useMutation(Api.createView, {
    ...params?.config,
    onSuccess: async (createdView, mutationFuncParams) => {
      const { logId, organizationId } = mutationFuncParams;

      // Set cache for that single view
      const keyParams = {
        logId,
        organizationId,
        projectId: createdView.projectId,
        viewId: createdView.id,
      };
      setViewCache(keyParams, createdView);

      // Invalidate any cache that includes info about views
      invalidateViewCache(ViewCacheEvents.CREATE, keyParams);

      return params?.config?.onSuccess?.(createdView, mutationFuncParams);
    },
  });
