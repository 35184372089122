import React from 'react';
import { useQuerySteps } from '../../contexts/QueryStepsContext';
import { SingleQueryStepProvider } from '../../contexts/SingleQueryStepContext';
import { QueryStepFactory } from '../QuerySteps/QueryStepFactory';

export function QueryStepsAccordionGroup({
  className,
}: {
  className?: string;
}) {
  const { getInProgressQuerySteps } = useQuerySteps();

  return (
    <div className={className}>
      {getInProgressQuerySteps().map((_, idx) => (
        <SingleQueryStepProvider
          index={idx}
          // eslint-disable-next-line react/no-array-index-key
          key={idx}
        >
          <QueryStepFactory />
        </SingleQueryStepProvider>
      ))}
    </div>
  );
}
