import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { Loading } from '@monorepo/shared/components/Loading';
import { Button } from '@monorepo/shared/componentsV2/Button';
import { Description } from '@monorepo/shared/componentsV2/Description';
import {
  Content as StyledMain,
  Page,
  Title,
} from '@monorepo/shared/componentsV2/fullPageWizard/styled/fullPageWizard';
import { List } from '@monorepo/shared/componentsV2/List';
import { useToast } from '@monorepo/shared/contexts/ToastContext';
import { useDisableSubnav } from '@monorepo/shared/hooks/subnav/useDisableSubnav';
import { useModal } from '@monorepo/shared/hooks/useModalV2';
import { useOpenClose } from '@monorepo/shared/hooks/useOpenClose';
import * as Sentry from '@sentry/browser';
import { FieldResponse } from 'mapistry-shared';
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import styled from 'styled-components';
import { useResourcePropertyDelete } from '../../hooks/useResourcePropertyDelete';
import { useResourceTypeAssetsOrShowError } from '../../hooks/useResourceTypeAssetsOrShowError';
import { NamePropertyPreview } from '../BuiltInPropertyPreviews/NamePropertyPreview';
import { SitePropertyPreview } from '../BuiltInPropertyPreviews/SitePropertyPreview';
import {
  EditResourceTypeHeader,
  WizardStep,
} from '../EditResourceTypeHeader/EditResourceTypeHeader';
import { EditResourcePropertyModal } from './EditResourcePropertyModal';
import { ResourcePropertyPreview } from './ResourcePropertyPreview';

const Content = styled.div`
  max-width: 615px;
`;

const DescriptionStyled = styled(Description)`
  margin-bottom: 0.6rem;
`;

export function EditResourcePropertiesPage() {
  useDisableSubnav();
  const { organizationId, resourceTypeId } = useParams();
  const { isError, isLoading, resourceProperties, resourceType } =
    useResourceTypeAssetsOrShowError(organizationId, resourceTypeId);

  const [propertyToEdit, setPropertyToEdit] = useState<
    FieldResponse | undefined
  >(undefined);
  const [open, setOpen, setClosed] = useOpenClose();
  const openModal = useCallback(
    (property?: FieldResponse) => {
      setPropertyToEdit(property);
      setOpen();
    },
    [setOpen],
  );

  const { confirm } = useModal();
  const [deleter] = useResourcePropertyDelete({
    config: { throwOnError: true },
  });
  const { showUserFriendlyErrorToast, success: showSuccess } = useToast();
  const handleDelete = useCallback(
    async (property: FieldResponse) => {
      const isConfirmed = await confirm({
        title: 'Delete Resource Property',
        description: (
          <>
            Are you sure you want to delete the <b>{property.name}</b> Resource
            property?
          </>
        ),
        cancelButtonText: 'Keep property',
        confirmButtonText: 'Delete property',
        danger: true,
      });

      if (!isConfirmed) return;

      try {
        if (!organizationId || !resourceTypeId) {
          const errMsg = `Couldn't delete Resource property: This page doesn't have Resource type id or organization id`;
          Sentry.captureException(errMsg);
          throw new Error(errMsg);
        }
        await deleter({
          organizationId,
          resourceTypeId,
          resourcePropertyId: property.id,
        });
        showSuccess(`${property.name} has been deleted.`);
      } catch (error) {
        showUserFriendlyErrorToast(
          error,
          `Unable to delete "${property.name}" Resource property.`,
        );
      }
    },
    [
      confirm,
      deleter,
      organizationId,
      resourceTypeId,
      showUserFriendlyErrorToast,
      showSuccess,
    ],
  );

  return (
    <Page>
      <EditResourceTypeHeader wizardStep={WizardStep.PROPERTIES} />
      <StyledMain>
        <Content>
          <Title id="resource-type-properties">Resource Type Properties</Title>
          <DescriptionStyled>
            These fields will be associated with every Resource you add to this
            Resource type.
          </DescriptionStyled>
          {isLoading && <Loading />}
          {!isLoading && !isError && resourceType && resourceProperties && (
            <List aria-labelledby="resource-type-properties">
              <NamePropertyPreview key="name" />
              {resourceType.isSiteSpecific && (
                <SitePropertyPreview key="site" />
              )}
              {resourceProperties.map((property) => (
                <ResourcePropertyPreview
                  key={property.id}
                  onDelete={handleDelete}
                  onEdit={openModal}
                  property={property}
                />
              ))}
            </List>
          )}
          <Button
            color="primary"
            disabled={isLoading || isError}
            onClick={() => openModal()}
          >
            <AddCircleOutlineIcon />
            Add a property
          </Button>
        </Content>
      </StyledMain>
      <EditResourcePropertyModal
        onClose={setClosed}
        open={open}
        property={propertyToEdit}
      />
    </Page>
  );
}
