import { GroupedColumn, isGroupedColumnSet } from 'mapistry-shared';

export const getInitialLimitCondition = (
  groupedColumns: GroupedColumn[] = [],
) => {
  const groupByFilters = groupedColumns.map((groupedColumn) => {
    if (isGroupedColumnSet(groupedColumn)) {
      return { columnName: groupedColumn.primaryColumn, value: '' };
    }
    return { columnName: groupedColumn, value: '' };
  });

  return { groupByFilters };
};
