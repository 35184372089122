import { useQuerySteps } from '@monorepo/logs/src/contexts/QueryStepsContext';
import { useSingleQueryStep } from '@monorepo/logs/src/contexts/SingleQueryStepContext';
import { EditResourceFieldValue } from '@monorepo/shared/componentsV2/fieldDataType/values/EditFieldValueFactory/EditResourceFieldValue';
import { TextField } from '@monorepo/shared/componentsV2/fields/TextField';
import { Loading } from '@monorepo/shared/componentsV2/Loading';
import { useLogInfo } from '@monorepo/shared/hooks/logs/useLogInfo';
import { isRequired } from '@monorepo/shared/utils/validators';
import {
  ColumnType,
  FieldDataType,
  FieldResponse,
  GroupByFilter,
  ResourceFieldResponse,
} from 'mapistry-shared';
import React, { useCallback } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import styled from 'styled-components';

const StyledEditResourceFieldValue = styled(EditResourceFieldValue)`
  width: 100%;
`;

const isResourceField = (
  x: FieldResponse | undefined,
): x is ResourceFieldResponse => x != null && x.type === FieldDataType.RESOURCE;

interface GroupByColumnProps {
  hideLabels?: boolean;
  name: string;
}

export function GroupByColumn({
  hideLabels = false,
  name,
}: GroupByColumnProps) {
  const { logId, organizationId, projectId } = useQuerySteps();
  const { availableColumns, groupedColumns } = useSingleQueryStep();
  const hasGroupedColumns = !!groupedColumns.length;
  const { logInfo, isLoading } = useLogInfo({
    config: { enabled: hasGroupedColumns },
    logId,
    organizationId,
  });

  const getResourceField = useCallback(
    (fieldId: string | undefined) => {
      if (fieldId == null) {
        return undefined;
      }
      const resourceField = logInfo?.log.fields.find(
        (field) => field.id === fieldId,
      );
      if (isResourceField(resourceField)) {
        return resourceField;
      }
      return undefined;
    },
    [logInfo],
  );

  return isLoading ? (
    <Loading />
  ) : (
    <FieldArray<GroupByFilter, never> name={`${name}.groupByFilters`}>
      {({ fields: groupByFilters }) => (
        <>
          {groupByFilters.map((groupByFilterName, idx) => {
            const groupByFilter = groupByFilters.value[idx];
            const column = availableColumns.find(
              (col) => col.columnName === groupByFilter?.columnName,
            );
            if (!column) {
              throw new Error('Column not found');
            }

            if (column.columnType === ColumnType.FOREIGN_ID) {
              const resourceField = getResourceField(column.fieldId);
              if (!resourceField) {
                return <Loading />;
              }
              return (
                <StyledEditResourceFieldValue
                  field={resourceField}
                  fullWidth
                  hiddenLabel={hideLabels}
                  isRequired
                  key={groupByFilterName}
                  label={`${idx === 0 ? 'Where' : 'and'} ${
                    column.columnLabel
                  } is`}
                  name={`${groupByFilterName}.value`}
                  organizationId={organizationId}
                  projectId={projectId}
                />
              );
            }

            return (
              <TextField
                fullWidth
                hiddenLabel={hideLabels}
                key={groupByFilterName}
                label={`${idx === 0 ? 'Where' : 'and'} ${
                  column.columnLabel
                } is`}
                name={`${groupByFilterName}.value`}
                placeholder="Enter a value"
                required
                validate={isRequired}
              />
            );
          })}
        </>
      )}
    </FieldArray>
  );
}
