import { useOpenClose } from '@monorepo/shared/hooks/useOpenClose';
import React, { createContext, useMemo, useState } from 'react';
import { EditViewInfoModal } from '../components/EditViewInfoModal';
import { EditSiteLogViewResponse } from '../types';

type EditViewInfoModalContext = {
  open: boolean;
  openModal: (view?: EditSiteLogViewResponse) => void;
  closeModal: () => void;
  view: EditSiteLogViewResponse | undefined;
};

export const EditViewInfoModalContext = createContext<
  EditViewInfoModalContext | undefined
>(undefined);

type EditViewInfoModalProviderProps = {
  children: React.ReactNode;
};

export const EditViewInfoModalProvider = ({
  children,
}: EditViewInfoModalProviderProps) => {
  const [isOpen, open, close] = useOpenClose();
  const [view, setView] = useState<EditSiteLogViewResponse | undefined>();

  const contextValue = useMemo(
    () => ({
      closeModal: () => {
        close();
        setView(undefined);
      },
      open: isOpen,
      openModal: (v?: EditSiteLogViewResponse) => {
        setView(v);
        open();
      },
      view,
    }),
    [isOpen, view, close, open],
  );

  return (
    <EditViewInfoModalContext.Provider value={contextValue}>
      {isOpen && (
        <EditViewInfoModal onClose={contextValue.closeModal} view={view} />
      )}
      {children}
    </EditViewInfoModalContext.Provider>
  );
};
