import { initCacheInvalidatorRegister } from '../cacheRegisterConstructor';

export enum ViewCacheEvents {
  CREATE = 'CREATE',
  DELETE = 'DELETE',
  UPDATE = 'UPDATE',
}

interface CacheParams {
  logId: string;
  organizationId: string;
  projectId: string;
  viewId: string;
}

const cacheRegister = initCacheInvalidatorRegister<
  typeof ViewCacheEvents,
  CacheParams
>(ViewCacheEvents);

export const invalidateViewCache = cacheRegister.invalidate;
export const viewCacheRegister = cacheRegister.register;
