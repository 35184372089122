import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Divider from '@material-ui/core/Divider';
import MuiMenuItem from '@material-ui/core/MenuItem';
import MuiMenuList from '@material-ui/core/MenuList';
import MuiPopper, { PopperPlacementType } from '@material-ui/core/Popper';
import { KeyService } from '@monorepo/shared/KeyService';
import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components';
import { bodyMedium, bodyRegular, buttonText } from '../styles/text';

const PopperContainer = styled(MuiPopper)`
  z-index: ${({ theme }) => theme.constants.menuZIndex};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 2px;
  box-shadow: 0 4px 13px ${({ theme }) => theme.colors.blue20};
`;

const TitleContainer = styled.p`
  ${bodyMedium};
  padding: 0 1.5rem;
  color: ${({ theme }) => theme.colors.gray333};
  background-color: ${({ theme }) => theme.colors.white};
`;

const MenuList = styled(MuiMenuList)`
  display: flex;
  flex-direction: column;
  min-width: 100px;
  padding: 0;
`;

const MenuItem = styled(MuiMenuItem)<{
  width?: number;
  $hasMenuTitle?: boolean;
}>`
  ${({ $hasMenuTitle }) => ($hasMenuTitle ? bodyRegular : buttonText)};
  justify-content: left;
  width: ${({ width }) => (width ? `${width}px` : '')};
  height: 2.125rem;
  padding: ${({ $hasMenuTitle }) => $hasMenuTitle && '1.5rem'};
  font-weight: 400;
  color: ${({ $hasMenuTitle, theme }) =>
    $hasMenuTitle ? theme.colors.gray333 : theme.colors.lightBlue};
  -webkit-text-stroke: 0.5px ${({ theme }) => theme.colors.darkerBlue};
  border-radius: 0;

  &:hover,
  &:focus-visible {
    background-color: ${({ $hasMenuTitle, theme }) =>
      $hasMenuTitle ? theme.colors.blue04 : theme.colors.lightBlue08};
  }
`;

const IconContainer = styled.div`
  display: inline-flex;
  flex-shrink: 0;
  padding-right: 1rem;

  & svg {
    height: 0.8rem;
  }
`;

type MenuOption = {
  displayName: string;
  icon?: ReactNode;
  onClick: React.ComponentProps<typeof MenuItem>['onClick'];
};

type MenuProps = {
  // NOTE! Any onClick handler already set on this element will be overwritten. onClick for this element will open the popper.
  buttonElement: ReactElement;
  /** e.g. when rendering inside a dialog */
  disablePopperPortal?: boolean;
  options: MenuOption[];
  menuWidthShouldMatchButtonEl?: boolean;
  menuTitle?: string;
  popperPlacement?: PopperPlacementType;
};

export function Menu({
  buttonElement,
  options,
  menuWidthShouldMatchButtonEl = false,
  menuTitle,
  popperPlacement = 'bottom-end',
  disablePopperPortal = false,
}: MenuProps) {
  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);

  const handleAnchorElClick = useCallback(
    (event) => setAnchorEl(anchorEl ? null : event.currentTarget),
    [anchorEl, setAnchorEl],
  );

  const handleClose = useCallback(() => {
    // restore logical keyboard state by returning focus to the element that opened the menu
    anchorEl?.focus();
    setAnchorEl(null);
  }, [anchorEl, setAnchorEl]);

  const handleKeyDown = useCallback(
    (event) => {
      // any interaction within the menu is with up/down arrow keys
      if (
        KeyService.isEscapeKey(event.keyCode) ||
        KeyService.isTabKey(event.keyCode)
      ) {
        handleClose();
      }
    },
    [handleClose],
  );

  // add/override click handler on the button element to trigger opening the popper
  const buttonElementWithClickHandler = useMemo(
    () => React.cloneElement(buttonElement, { onClick: handleAnchorElClick }),
    [buttonElement, handleAnchorElClick],
  );

  const anchorElWidth = anchorEl?.offsetWidth || 0;

  return (
    <>
      {buttonElementWithClickHandler}

      <PopperContainer
        disablePortal={disablePopperPortal}
        anchorEl={anchorEl}
        open={!!anchorEl}
        placement={popperPlacement}
      >
        {menuTitle && (
          <>
            <TitleContainer>{menuTitle}</TitleContainer>
            <Divider />
          </>
        )}
        <ClickAwayListener onClickAway={handleClose}>
          <MenuList autoFocusItem={!!anchorEl} variant="menu">
            {options.map((opt) => (
              <MenuItem
                key={opt.displayName}
                onClick={(event) => {
                  // TODO: Fix this the next time the file is edited.
                  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                  opt.onClick && opt.onClick(event);
                  handleClose();
                }}
                onKeyDown={handleKeyDown}
                $hasMenuTitle={!!menuTitle}
                width={menuWidthShouldMatchButtonEl ? anchorElWidth : undefined}
              >
                {opt.icon && (
                  <IconContainer aria-hidden>{opt.icon}</IconContainer>
                )}
                {opt.displayName}
              </MenuItem>
            ))}
          </MenuList>
        </ClickAwayListener>
      </PopperContainer>
    </>
  );
}
