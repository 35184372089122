import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMore from '@material-ui/icons/ExpandMore';
import React from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

const StyledAccordion = styled(MuiAccordion)`
  &,
  &.Mui-expanded {
    margin: 0;
    border-top: 1px solid ${({ theme }) => theme.colors.grayddd};
    border-bottom: 1px solid ${({ theme }) => theme.colors.grayddd};

    &::before {
      /* hide the built-in top "border" */
      height: 0;
    }
  }
`;

const StyledAccordionSummary = styled(MuiAccordionSummary)`
  color: ${({ theme }) => theme.colors.gray333};
  background-color: ${({ theme }) => theme.colors.blue04};

  &,
  &.Mui-expanded {
    /* keep the same height & margins when expanded */
    min-height: unset;

    & .MuiAccordionSummary-content {
      margin: 0.5rem 0;
    }

    &:focus-visible {
      background-color: ${({ theme }) => theme.colors.grayeee};
    }
  }
`;

type AccordionProps = Pick<
  React.ComponentProps<typeof MuiAccordion>,
  'defaultExpanded' | 'disabled'
> & {
  className?: string;
  detailsContent: React.ReactNode;
  expandIcon?: React.ComponentProps<typeof MuiAccordionSummary>['expandIcon'];
  summaryContent: React.ReactNode;
};

export function Accordion({
  className,
  defaultExpanded,
  detailsContent,
  disabled,
  expandIcon,
  summaryContent,
}: AccordionProps) {
  const attributePrefix = `accordion-${uuidv4()}`;
  return (
    <StyledAccordion
      className={className}
      defaultExpanded={defaultExpanded}
      disabled={disabled}
      elevation={0}
      square
    >
      <StyledAccordionSummary
        /* from MUI: For optimal accessibility we recommend setting id and aria-controls on the AccordionSummary.
        The Accordion will derive the necessary aria-labelledby and id for the content region of the accordion. */
        aria-controls={`${attributePrefix}-content`}
        id={`${attributePrefix}-header`}
        expandIcon={expandIcon === undefined ? <ExpandMore /> : expandIcon}
      >
        {summaryContent}
      </StyledAccordionSummary>
      <MuiAccordionDetails>{detailsContent}</MuiAccordionDetails>
    </StyledAccordion>
  );
}
