import { Button } from '@monorepo/shared/componentsV2/Button/Button';
import { Menu } from '@monorepo/shared/componentsV2/Menu';
import { useCurrentUser } from '@monorepo/shared/hooks/useCurrentUser';
import Settings from '@svg/m-cog.svg';
import React from 'react';
import styled from 'styled-components';

export const NavContainer = styled.nav`
  margin-top: auto; /* pull to bottom */
`;

export const ButtonContainer = styled(Button)`
  width: 100%;
  height: ${({ theme }) => theme.sizes.appBarHeight};

  & span {
    &.MuiButton-label {
      display: flex;
      flex-wrap: wrap;
      font-size: 12px;
      color: ${({ theme }) => theme.colors.white};
      text-shadow: ${({ theme }) => `${theme.colors.gray666} 0 0 1px`};
      opacity: 0.9;
    }
  }

  & svg {
    width: 1.75rem;
    height: 1.75rem;
    margin-right: 0;
    fill: ${({ theme }) => theme.colors.white56};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.white16};
  }

  &:focus-visible,
  /*  competing with more specific styles in TextDefaultButton */
  &:not(.Mui-disabled):focus-visible {
    background-color: transparent;
    outline: 1px auto ${({ theme }) => theme.colors.gray333};
    outline-offset: -2px;
  }
`;

export function SettingsTab() {
  const { currentUser } = useCurrentUser();

  return (
    <NavContainer aria-label="Settings">
      <Menu
        menuTitle={currentUser?.email}
        buttonElement={
          <ButtonContainer>
            <span aria-hidden>
              <Settings />
            </span>
            Settings
          </ButtonContainer>
        }
        options={[
          {
            displayName: 'Settings',
            onClick: () => {
              window.location.href = '/dashboard/settings/notifications';
            },
          },
          {
            displayName: 'Logout',
            onClick: () => {
              window.location.href = '/logout';
            },
          },
        ]}
        popperPlacement="right-end"
      />
    </NavContainer>
  );
}
