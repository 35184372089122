import { useSingleQueryStep } from '@monorepo/logs/src/contexts/SingleQueryStepContext';
import { SelectField } from '@monorepo/shared/componentsV2/fields/SelectField';
import { IconButton } from '@monorepo/shared/componentsV2/Button/IconButton';
import { VisuallyHidden } from '@monorepo/shared/componentsV2/VisuallyHidden';
import { isRequired } from '@monorepo/shared/utils/validators';
import TrashIcon from '@svg/trash.svg';
import { ColumnSet, ColumnType, QueryColumn } from 'mapistry-shared';
import React, { useMemo } from 'react';
import { useFormState } from 'react-final-form';
import { DeepPartial } from 'redux';
import styled from 'styled-components';
import { FormValues } from './types';

const Container = styled.div`
  display: flex;
`;

const StyledSelectField = styled(SelectField)`
  width: 25rem;
`;

const DeleteButton = styled(IconButton)`
  min-width: 2rem;
  margin-bottom: 1.3rem; /* lines this up vertically with the input elements to its left */
  font-size: 0.75rem;
`;

export function getAllowedGroupByColumns(
  availableColumns: QueryColumn[],
  columnSets: ColumnSet[],
  currentFormValues: DeepPartial<FormValues>,
  fieldIndex?: number,
): QueryColumn[] {
  const alreadySelectedGroupBys = (currentFormValues?.groupBy || []).filter(
    (columnName): columnName is string => !!columnName,
  );

  const disallowedColumnOptions = alreadySelectedGroupBys.reduce(
    (it: string[], groupByColumnName: string, idx: number) => {
      // if we're looking at selected column for the dropdown we're getting the options for, don't remove column options
      //  that that selection disqualifies
      // Examples:
      //  - if a certain group by column is set to column "Type", the dropdown for that should still show "Type" as an option
      //  - if it is set to a resource, the dropdown should allow switching from that resource to one of its dependent fields
      //  - if it is set to a resource property, the dropdown should allow switching to the resource itself (UNLESS another
      //    selected group by column also is set to one of that resource's properties)
      if (idx === fieldIndex) {
        return it;
      }

      // not allowed to group by the same column multiple times
      const toAdd: string[] = [groupByColumnName];

      // for each column set, can only choose the primary column OR a subset of the dependent columns
      //  (if you are grouping by resource, for example, it is redundant to group by a specific property on that resource)
      columnSets.forEach((cs) => {
        if (cs.primaryColumn === groupByColumnName) {
          toAdd.push(...cs.dependentColumns);
        } else if (cs.dependentColumns.includes(groupByColumnName)) {
          toAdd.push(cs.primaryColumn);
        }
      });

      return [...it, ...toAdd];
    },
    [],
  );

  const groupByOptions = availableColumns
    .filter((c) =>
      [ColumnType.TEXT, ColumnType.FOREIGN_ID, ColumnType.BOOLEAN].includes(
        c.columnType,
      ),
    )
    .filter(
      (c) =>
        !['ID', 'SITE_ID', 'SITE_NAME'].includes(c.columnName) && // no reason to ever group by these
        !disallowedColumnOptions.includes(c.columnName),
    );
  return groupByOptions;
}

export function AggregationGroupBy({
  formFieldName,
  formFieldIndex,
  onDelete,
}: {
  formFieldName: string;
  formFieldIndex: number;
  onDelete?: () => void;
}) {
  const { isLastStep, availableColumns, columnSets } = useSingleQueryStep();

  const { values: currentFormValues } = useFormState<DeepPartial<FormValues>>();

  const groupByOptions = useMemo(
    () =>
      getAllowedGroupByColumns(
        availableColumns,
        columnSets,
        currentFormValues,
        formFieldIndex,
      ).map(({ columnName, columnLabel }) => ({
        label: columnLabel,
        value: columnName,
      })),
    [availableColumns, columnSets, currentFormValues, formFieldIndex],
  );

  return (
    <Container>
      <StyledSelectField
        disabled={!isLastStep}
        hiddenLabel
        name={formFieldName}
        label={`Group by column ${formFieldIndex + 1}`}
        placeholder="Column to group by"
        required
        validate={isRequired}
        options={groupByOptions}
      />
      {isLastStep && onDelete && (
        <DeleteButton onClick={onDelete}>
          <TrashIcon />
          <VisuallyHidden>Delete group by</VisuallyHidden>
        </DeleteButton>
      )}
    </Container>
  );
}
