import { Api } from '@monorepo/shared/apiClient';
import { MutationConfig, useMutation } from 'react-query';
import { setCache } from './useLogQuery';

type UseLogQueryValidateParams = {
  config?: MutationConfig<Api.ValidateQueryResponse, Api.ErrorResponse>;
};

export const useLogQueryValidate = (params?: UseLogQueryValidateParams) =>
  useMutation(Api.validateQuery, {
    ...params?.config,
    onSuccess: (queryResult, validateQueryParams) => {
      setCache(validateQueryParams, queryResult);
      return params?.config?.onSuccess?.(queryResult, validateQueryParams);
    },
  });
